import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function TaxDeductionRegistration() {
  /* Slider */
  // const societySlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online 80G and 12A Registration`,
  //     content: `Secure and fast 80G and 12A registration`,
  //     image: "/imgs/business/dsc-pvt.png",
  //     alt_tag: "Online 80G and 12A Registration Near Banglore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `80G and 12A Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const societyAboutData = {
    header: `Online 80G And 12A Registration`,
    sub_title: `Best 80G and 12A Tax reduction registration Service providers in Hosur.`,
    content_paragraph: [
      `For income tax exemption for the best, NGO's in India must submit an online 12A & 80G Registration application! 
      Additionally, it aids NGO's in obtaining government funding and sponsorship. You can reach the knowledgeable staff
       at TODAYFILINGS via phone for assistance and guidance.`,

      // `An NGO or non-governmental organization, such as a Section 8 Company or a charity trust, may apply for an
      // 80G Certificate in India, which is a legal document issued by the Income Tax Department. `,
    ],
  };

  /* Scroll Nav Data */
  const societyScrollId = [
    {
      id: `#condition`,
      heading: `Conditions`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#cancel`,
      heading: `12A Cancelation`,
    },
  ];

  /* Img Content Component Data */
  const societyIcData = {
    id: 'condition',
    mt_div: '',
    mt_img: '',
    header: 'Conditions Registrations Of 80G And 12A Apply',
    image: '/imgs/registration/ngo/80g-registration.png',
    alt_tag: '80g and 12a condition registration',
    points: [
      {
        head: ``,
        content: `Either a charity trust or a recognized society should be the organization.`,
        icon: true,
      },
      {
        head: '',
        content: `These entities shouldn't be using their assets or income for anything other than charitable purposes.`,
        icon: true,
      },
      {
        head: '',
        content: `The group shouldn't be working to further the interests of any specific caste, community, or religion.`,
        icon: true,
      },
      {
        head: '',
        content: `Any funds collected by the NGO should only be utilized for charitable purposes.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const societyCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: '80G And 12A Registration Procedures',
    body: [
      {
        head: `Registration of 80G Certificate`,
        points: ``,
        icon: false,
      },
      {
        points: `Submit an application for an 80G certificate along with all necessary paperwork and 
        reports to the entity's designated Commissioner of Income Tax (Exemption).`,
        icon: true,
      },
      {
        points: `The Income Tax department will conduct an on-site inspection after receiving the 
        application, and the necessary paperwork supplied for 80G registration is also reviewed.`,
        icon: true,
      },
      {
        points: `However, if they feel anything is lacking or if they appear unfit, the worried 
        income tax officials may ask for more paperwork, reports, or proof.`,
        icon: true,
      },
      {
        points: `The Commissioner issues the organization an 80G certificate after conducting a 
        satisfactory review of the provided documentation and the NGO's office.`,
        icon: true,
      },
      {
        points: `Throughout the existence of the organization, an 80G Certificate is valid.`,
        icon: true,
      },
      {
        head: `Registration of 12A Certificate`,
        points: ``,
        icon: false,
      },
      {
        points: `According to Rule 17A of the Income Tax Act of 1961, the applicant or the 
        entity must first submit an application in Form 10A.`,
        icon: true,
      },
      {
        points: `According to the requirements of the Jurisdictional Commissioner of Income 
        Tax department, the application must be filled out completely (Exemption).`,
        icon: true,
      },
      {
        points: `The authorized Commissioner will assess the application and supporting 
        documentation after they are received and decide whether or not to approve registration.`,
        icon: true,
      },
      {
        points: ` The applicant is given a fair opportunity to be heard if the Commissioner rejects 
        the application because the materials and application form have not satisfied him.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const societyCiData = {
    id: 'cancel',
    background: 'main-content',
    mt_div: '',
    header: '12A Certificate Cancelation',

    points: [
      {
        head: '',
        content: `12A registration certificate may be revoked at any time upon showing that a 
        company or other body is engaged in the following activities:`,
        icon: true,
      },
      {
        head: '',
        content: `If it conflicts with the organization's mission."`,
        icon: true,
      },
      {
        content: `If the thing isn't genuine.`,
        icon: true,
      },
      {
        content: `If a certain caste or religion is benefited by the organization.`,
        icon: true,
      },
      {
        content: `If the money and contributions are used for illegal purposes.`,
        icon: true,
      },
      {
        content: `If an institution's financial success or wealth is used to help a particular person, organization, or other entity`,
        icon: true,
      },
    ],
    image: '/imgs/business/document-pvt.png',
    alt_tag: '80g and 12a registration',
  };

  var FAQ_data = [
    {
      header: `What exactly is an 80G Certificate?`,
      body: [
        {
          content: `An 80G Certificate is a legal document issued by the Income Tax
          Department to a non-governmental entity, such as a Section 8
          Corporation or a charity trust. It is provided in order to
          encourage and urge donors to contribute funds to such non-profit
          organizations.`,
          icon: false,
        },
      ],
    },
    {
      header: ` What is 80G qualifying limit? `,
      body: [
        {
          content: `Some donations meet all requirements for either half or 100% assessment conclusion though some 
          others fit the bill for a similar finding yet up to a most extreme restriction of 10% of Adjusted Gross 
          Total Income of the citizen.`,
          icon: false,
        },
      ],
    },
    {
      header: ` What are the some features of Section 80G? `,
      body: [
        {
          content: `Donation in real money or check are qualified for the duty derivation.`,
          icon: true,
        },
        {
          content: `All donations are not qualified for tax reductions. Tax reductions can 
          be guaranteed uniquely on explicit gifts for example those made to recommended assets
           and establishments.`,
          icon: true,
        },
        {
          content: `For asserting reasoning under Section 80G, a receipt gave by the beneficiary trust,called as
           donee, is an unquestionable requirement. The receipt must contain the name, address and PAN of the 
           Trust, the name of the benefactor, the sum gave.`,
          icon: true,
        },
      ],
    },

    {
      header: 'What is the qualifying limit for the 80G?',
      body: [
        {
          content: `Some donations meet all standards for either a full or partial
          assessment conclusion, while others meet all requirements for the
          same result but up to a maximum limit of 10% of the person's
          Adjusted Gross Total Income.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Why 12A registration is required? ',
      body: [
        {
          content: `12A registration is a one-time registration which is granted by the Income Tax Department 
          to trusts and other not for profit organisations. The purpose of the registration is to be exempted 
          from the payment of income tax. 12A registration is generally applied for immediately after incorporation.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the documents required for 12A registration? ',
      body: [
        {
          content: `Form 10A.`,
          icon: true,
        },
        {
          content: `Documental evidence of the creation of the Trust or NGO – Trust Deed of a Trust; Registration 
          Certificate and Memorandum of Association of a society; section 8 companies to submit a certificate of 
          incorporation and copies of MoA and AoA of the company.`,
          icon: true,
        },
        {
          content: `Three-year bank account statement of the Trust.`,
          icon: true,
        },
        {
          content: `PAN card of the organization.`,
          icon: true,
        },
      ],
    },
    {
      header: `What exactly is a 12A Certificate?`,
      body: [
        {
          content: `Section 12 A of the Income Tax Act of 1961 provides a one-time tax
          exemption to trusts, non-profit organizations, and Section 8
          corporations. Organizations that have a registration certificate
          under Section 12A are eligible for tax exemption, which means they
          are not required to pay taxes on their excess financial gain.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the purpose of 12A registration?`,
      body: [
        {
          content: `Section 12A of the Income Tax Act of 1961 provides a one-time tax
          exemption to trusts, non-profit organizations, and Section 8
          corporations. 12A registration procedure is implemented
          immediately upon the establishment of an entity. The goal of this
          registration is to be exempt from paying income taxes.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are some of the characteristics of Section 80G?`,
      body: [
        {
          content: `An 80G Certificate is a legal document issued by the Income Tax
          Department to a non-governmental organization, such as a Section 8
          Corporation or a charity trust.`,
          icon: true,
        },
        {
          content: `The 80G Certificate is issued to encourage and promote donors to
          contribute funds to non-profit entities such as NGOs.`,
          icon: true,
        },
        {
          content: `Additionally, if a person donates to such a charity, they will
          receive a 50% tax exemption and be eligible to deduct that amount
          from their gross total income.`,
          icon: true,
        },
        {
          content: `This information must include the organization's PAN number, the
          donor's name, and the date of the gift or donation.`,
          icon: true,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='80G and 12A Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={societySlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={societyAboutData} />

          <Counter />

          <ScrollNav scroll_data={societyScrollId} />

          <ImgContent item={societyIcData} />

          <ContentForm CFSection_data={societyCfData} />

          <section className='mt-5'>
            <div className='container mt-5'>
              <div className='row mt-5'>
                <div className='col-lg-12 col-md-12 col-sm-12 content'>
                  <div className='desc ipr'>
                    <section id='documents' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>
                            Required documents for 80G and 12A registration
                            <div className='bar'></div>
                          </h2>
                        </header>
                        <div className='row'>
                          <div className='col-md-5'>
                            <h5>Required documents for 80G Registration</h5>
                            <p>
                              The documents essential for 80G registration in
                              India are listed below:
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Original MoA, RC, or Trust Deed, as well as
                              comprehensive details regarding the Board of
                              Trustees and NOC of the organization property.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Form 10G and A copy of a Pan card and utility
                              bill, such as a water or electricity bill, or a
                              receipt for house taxes.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              The essential records for annual returns, income
                              tax returns, and the last three years' record
                              books of accounts.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>A list
                              of the welfare, charitable, or social activities
                              being carried out, along with a three-year status
                              report.
                            </p>
                          </div>
                          <div className='col-md-2'></div>
                          <div className='col-md-5'>
                            <h5>Required documents for 12A Registration</h5>
                            <p>
                              The documents essential for 12A registration in
                              India are listed below:
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Form 10A with PAN of the organization or entity.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              For the last three years, the Trust's bank account
                              statement.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Section 8 corporations must show their certificate
                              of formation, copies of the MoA, and the entity's
                              AoA.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Evidence supporting the establishment of the Trust
                              or NGO, such as the Trust Deed.in the case of a
                              Trust and the Registration Certificate and
                              Memorandum of Association in the case of a
                              Society.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContentImg CISection_data={societyCiData} />
          <section></section>
          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
